/* v1.0 | 20080212 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

#cookie-law-info-bar {
    width:100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    bottom: 40px;
    left: 0;
    z-index: 10000;
}

#cookie-law-message {
    text-align:center;
    padding: 10px;
    color: #FFFFFF;
}

#cookie-law-close-button {
    color: #FFFFFF;
}

.contentProperties {
    border: 3px solid #9DBD36 !important;
    position: absolute !important;
    float: right !important;
    background-color: #9DBD36 !important;
    border-radius: 0px !important;
    cursor: pointer !important;
    opacity: 0.95 !important;
    box-shadow: none !important;
    z-index: 2147483647 !important;
    margin: 10px !important
}
.contentPropertiesClose {
    color: #fff !important;
    font-weight: 700 !important;
    padding: 0 20px !important;
}
.contentProperties table {
    margin: 0px !important;
    border: 0;
}
.contentProperties table td, .contentProperties table th {
    padding: 4px 7px !important;
}
.contentProperties table th {
    width: 50px !important;
    text-align: left !important;
    border-top: 0 !important;
    background-color: #545456 !important;
    text-align: right !important;
    cursor: move !important;
}
.contentProperties table td {
    width: 100px !important;
    border-top: 0 !important;
    background-color: #e8e8e8 !important;
    text-align: left !important;
    cursor: move !important;
}
.contentProperties table td, .contentProperties table th, .contentProperties table td a, .contentProperties table th a {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    font-weight: 400 !important;
    line-height: 15px;
}
.contentProperties table td, .contentProperties table td a, .contentProperties table th a {
    color: #555 !important;
}
.contentProperties table th {
    color: #fff !important;
}
.contentProperties table td a {
    cursor: pointer !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
}
